<template>
	<div id="dash-solicitudes">
		<div class="container">
			<h2 class="text-center">Solicitudes - Cesión</h2>
			<h3 class="text-center" v-if="exito == false">{{mensaje}}</h3>

			<div :key="solicitud.index" class="solicitudes" v-for="solicitud of solicitudes" :style="voto(solicitud)">
				<div class="solicitud">
					<div class="row row-mobile d-block d-sm-none">
						<div class="col">
							<div>
								<span class="label-datos-generales d-none d-sm-block f-white">Nombre</span>
								<span class="datos-generales-mobile f-white">{{ solicitud.nombre }}</span>
							</div>
						</div>
					</div>
					<div class="row row-mobile">
						<div class="col col-sm-2">
							<div>
								<div class="label-datos-generales">Id Solicitud</div>
								<div class="datos-generales">{{ solicitud.id }}</div>
							</div>
						</div>
						<div class="col col-sm-2 d-none d-sm-block">
							<div>
								<div class="label-datos-generales">Nombre</div>
								<div class="datos-generales">{{ solicitud.nombre }}</div>
							</div>
						</div>
						<div class="col col-sm-2">
							<div>
								<div class="label-datos-generales">Monto</div>
								<div class="datos-generales">${{ formatPrice(solicitud.importe_solicitado) }}</div>
							</div>
						</div>
						<div class="col col-sm-2">
							<div>
								<div class="label-datos-generales">Descripción</div>
								<div class="datos-generales">{{ solicitud.descripcion_solicitud }}</div>
							</div>
						</div>
						<div class="col col-sm-2">
							<div>
								<div class="label-datos-generales">Tipo Solicitud</div>
								<div class="datos-generales">{{ solicitud.tipo_solicitud }}</div>
							</div>
						</div>
						<div class="col col-sm-2" v-if="solicitud.create_cesion_credito">
							<div>
								<div class="label-datos-generales">Fecha cesión</div>
								<div class="datos-generales">{{ solicitud.create_cesion_credito }}</div>
							</div>
						</div>
						<button
							:data-target="'#solicitud-collapse-'+ solicitud.id"
							aria-controls="collapseSolicitud"
							aria-expanded="false"
							class="btn col d-none d-sm-block text-right"
							data-toggle="collapse"
							type="button"
						>
							<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down.svg" width="40px" />
						</button>
					</div>
					<button
						:data-target="'#solicitud-collapse-'+ solicitud.id"
						aria-controls="collapseSolicitud"
						aria-expanded="false"
						class="btn col d-block d-sm-none"
						data-toggle="collapse"
						type="button"
					>
						<img alt="logo" class="arrow-down" src="@/apps/comite/assets/img/arrow-down.svg" width="40px" />
					</button>
				</div>
				<div :id="'solicitud-collapse-' + solicitud.id" class="collapse">
					<div class="card card-body">
						<div>
							<div class="text-center">
								<h4>Generales:</h4>
							</div>
							<div class="generales-data">
								<span class="heanding-mobile">Descripción</span>
								<span class="col text-right">{{ solicitud.descripcion_solicitud }}</span>
							</div>
							<div class="generales-data">
								<span class="heanding-mobile">Medio de información</span>
								<span class="col text-right" > {{ solicitud.medio_informacion.canal }} </span>
							</div>
							<div class="generales-data">
								<span class="heanding-mobile">Promotor</span>
								<span class="col text-right">{{ solicitud.promotor.nombre }}</span>
							</div>
							<div class="generales-data">
								<span class="heanding-mobile">Ingresos mensuales</span>
								<span class="col text-right">${{ formatPrice(solicitud.ingreso_mensual) }}</span>
							</div>
							<div class="generales-data">
								<span class="heanding-mobile">Importe preautorizado</span>
								<span class="col text-right">${{ formatPrice(solicitud.importe_pre_autorizado) }}</span>
							</div>
							<div class="acciones-general col">
								<div class="row">
									<!-- <div class="col">
										<ul>
											<li>
												<a @click="aprobar(solicitud)" :data-target="'#comentarios-comite-' + solicitud.id" class="btn-primay" data-toggle="modal">
													<img alt="logo" class="icon-action" src="/img/aprove.svg" />
												</a>
											</li>
											<li>
												<a @click="rechazar(solicitud)" :data-target="'#comentarios-comite-' + solicitud.id" class="btn-primay" data-toggle="modal">
													<img alt="logo" class="icon-action" src="/img/cancel.svg" />
												</a>
											</li>
											<li>
												<a @click="comentarios(solicitud)" :data-target="'#comentarios-comite-' + solicitud.id" class="btn-primay" data-toggle="modal">
													<div class="text-center">
														<img alt="comentarios" class="icon-action" src="/img/coments.svg" />
													</div>
												</a>
											</li>
										</ul>
									</div> -->
									<div class="col detalle">
										<a @click.prevent="detalles_solicitud(solicitud.id)">Más detalles <img src="@/apps/comite/assets/img/detail.svg" alt="logo" width=15%></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@/apps/comite/api/comite'
import store from '@/store'

export default {

	data: () => ({
		exito: true,
		mensaje: '',
		estatus: null,
		solicitudes: [],
		solicitud: {
			historico: false
		},
		comite: null
	}),

	async mounted() {
		try{
			var menu = {
				title: 'Comité'
				,comite: 'Solicitudes'
				,comite_historico: 'Histórico'
			}

			if(this.$auth.getUser().roles.comite_ver_historico_cesion_creditos){
				var solicitudes = await api.solicitudesCesionCreditos()
			}

			this.$store.commit('setMenu', [
	      menu
	    ]);
			
			this.$log.info('solicitud', solicitudes.data);
			this.solicitudes = solicitudes.data.solicitudes;
			this.comite = solicitudes.data.id_comite;

			if(solicitudes.data?.solicitudes?.length == 0){
				this.exito = false
				this.mensaje = 'No hay solicitudes para mostrar.'
			}
		}catch(err) {
			if(err.response?.status == 401){
				this.exito = false
				this.mensaje = 'Acceso no autorizado'
			}else
			if(err.response?.status == 400){
				this.exito = false
				this.mensaje = err.response?.data?.message
			}else{
				this.$notify({
					group: 'alert'
					,type: 'error'
					,title: 'Error'
					,text: 'Ocurrió un error, favor de intentar más tarde.'
					,duration: 4000
				})
			}
			console.log(err);
		}
	},

	methods: {

		aprobar(solicitud) {
			this.solicitud = solicitud
			this.estatus = 4
			this.$modal.show('aceptar')
		},

		rechazar(solicitud) {
			this.solicitud = solicitud
			this.estatus = 5
			this.$modal.show('rechazar')
		},

		comentarios(solicitud) {
			this.solicitud = solicitud
			this.$modal.show('comentarios')
		},

		voto() {
			console.log(arguments)
		},

	    formatPrice(value) {
	        let val = (value/1).toFixed(0).replace(',', '.')
	        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
	    },
	    voto: function(solicitud) {
			let voto = null;
			solicitud.votaciones.forEach(votacion => {
				if (votacion.acreditador_id == this.$auth.getUser().id)
					voto = votacion;
			});

			if (voto !== null)
				if (voto.estado.nombre == 'aprobada')
					return 'background-color: #24b361';
				else
					return 'background-color: #dc3545';
			else
				return null;
		},
		detalles_solicitud: async function(id) {
			this.$router.push({name: 'comite_resumen_modelo', params:{id}});
		}
	}
}
</script>

<style lang="scss" scoped>

#dash-solicitudes {
	background-color: $white;
	// min-height: 100vh;
	margin-bottom: 2rem;
	.container{
		margin-bottom: 2rem;
	}
}
h2 {
	color: $text-primary;
	padding: 1rem 0;
}
.solicitudes {
	background: $color-blue;
	margin-top: 2rem;
	padding: 2rem 0 0;
	color: $white;
	border: none;
  box-shadow: 0 2px 18px 1px rgba(49,53,72,.1);
  border-radius: .45rem;

	.solicitud {
		padding: 0% 3% 2%;

		button {
			&:focus {
				outline: none !important;
				box-shadow: none;
			}
			&[aria-expanded="true"] {
				.arrow-down {
					transition: all 0.8s;
					transform: rotate(-180deg);
				}
			}
			&[aria-expanded="false"] {
				.arrow-down {
					transition: all 0.8s;
				}
			}
		}
		
		.f-white {
      color: #fff !important;
    }
	}

	@include mq-max(mobile-big) {
		.solicitud {
			text-align: center;
			.row-mobile {
				border-bottom: 1px dashed;
				margin-bottom: 2%;
				padding-bottom: 2%;
			}
			button {
				padding: 0%;
			}
		}
	}
}
.datos-generales {
	font-family: $nunito-regular;
}
.datos-generales-mobile {
	font-family: $nunito-semibold;
	font-size: 16px;
}
.label-datos-generales {
	font-family: $nunito-bold;
	font-size: 18px;
}
.generales-data {
	display: flex;
	justify-content: space-between;
	.text-right {
		font-weight: 700;
		padding-right: 0;
		color: $text-primary;
	}
	.heanding-mobile {
		color: $text-primary;
		font-size: 16px;
	}
}
.acciones-general {
	margin-top: 5%;
	padding-left: 0;
	ul {
		display: flex;
		padding-left: 0;
		justify-content: flex-start;
		margin-bottom: 0;

		li {
			list-style: none;
			margin-right: 8%;
			.icon-action {
				width: 40px;
			}
		}
		@include mq-min(tablet) {
			li {
				padding-right: 4%;
				margin-right: 2%;
			}
		}
	}

	.detalle {
		text-align: right;
		font-family: $nunito-regular;
		color: $color-blue;
		padding-right: 0%;
		align-items: center;
		display: flex;
		justify-content: flex-end;
		a{
			min-width: 150px;
			color: $color-blue;
			text-decoration: underline;
			&:hover{
				color: $color-green;
			}
		}
		img {
			&:hover {
				color: $color-green;
			}
		}
	}
	&:last-of-type{
		margin-bottom: 2rem;
	}
}
</style>